<!-- CompanyLayout.vue -->
<template>
    <div>
      <Header />
      <router-view></router-view>
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from "../components/companyLayout/Header.vue";
  import Footer from "../components/companyLayout/Footer.vue";
  
  export default {
    name: "CompanyLayout",
    components: { Header, Footer },
  };
  </script>
  