export const apis = {
    //Profile
    GET_PROFILE_BY_ID: 'driver/profile/',
    EDIT_PROFILE_BY_ID: 'driver/profile/',

    //Reviews
    GET_REVIEW: 'review',
    ADD_COMPANY_REVIEW: 'review/add-company-review',
    ADD_OUTSIDE_COMPANY_REVIEW: 'review/add-outside-company-review',
    PAST_REVIEW_BY_ID: 'driver/past-feedback/',
    MY_REVIEW_BY_ID: 'driver/my-review/',
    DELETE_REVIEW_BY_ID: 'review/delete-review/',
    
    //Place
    GET_PLACE: 'place',
    ADD_PLACE: 'place',
    MY_PLACE: 'driver/my-place/',
    ADD_PLACE_REVIEW: 'review/add-place-review',
    GET_PLACE_BY_ID: 'place/find/',
    EDIT_PLACE_BY_ID: 'place/find/',
    DELETE_PLACE_BY_ID: 'place/',
};