import axios from "axios";
import router from "@/router/routes";

export default function auth({ to, from, next, store }) {
  var token = localStorage.getItem("token");
  var isAuthenticated = Boolean(token);
  // console.log("isAuthenticated middelware--", isAuthenticated);
  // console.log("isAuthenticated store--", store);
  // console.log("isAuthenticated next--", next);
  console.log("isAuthenticated from--", from);
  // console.log("isAuthenticated to--", to);
  // console.log("isAuthenticated meta.portal--", to.meta.portal);

  if (isAuthenticated) {
    if (store.getters.auth) {
      //console.log('authenticate');
      next();
    } else {
      axios
        .get(axios.defaults.baseURL + "auth/current-user", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          // console.log("login details--from auth middleware--", response);
          const Response = response.data[0]

          if (Response.roles == "2" || Response.roles === 2) {
            let user = {
              address: Response.address,
              created_at: Response.created_at,
              company: Response.company,
              email: Response.email,
              id: Response.id,
              image: Response.imagelink,
              representative: Response.representative,
              roles: Response.roles,
              user_id: Response.user_id,
              service: Response.service,
              token: token,
            };
            store.commit("SET_LOGIN_DETAILS", user);
            store.commit("SET_USER_AUTHS", {isLoggedIn: true, token: token});
            store.commit("SET_COMPONENT_LAYOUT", { layout: "CompanyLayout" });
            localStorage.setItem("user", JSON.stringify(user));
            if(user.roles === 2){
              router.push("/company")
             }
          }
          else if (Response.roles == "3" || Response.roles === 3) {
            let user = {
              address: Response.address,
              contact: Response.contact,
              created_at: Response.created_at,
              description: Response.description,
              driver: Response.driver,
              email: Response.email,
              id: Response.id,
              image: Response.imagelink,
              position: Response.position,
              ratings: Response.ratings,
              roles: Response.roles,
              total_reviews: Response.total_reviews,
              updated_at: Response.updated_at,
              user_id: Response.user_id,
              vehicle_no: Response.vehicle_no,
              token: token,
            };
            store.commit("SET_LOGIN_DETAILS", user);
            store.commit("SET_USER_AUTHS", {isLoggedIn: true, token: token});
            store.commit("SET_COMPONENT_LAYOUT", { layout: "DriverLayout" });
            localStorage.setItem("user", JSON.stringify(user));
            if(user.roles === 3){
              router.push("/")
            }
          }
          else if(Response.roles == "1" || Response.roles === 1){
            let user = {
              name: Response.name,
              email: Response.email,
              id: Response.id,
              roles: Response.roles,
              token: token,
            };
            store.commit("SET_LOGIN_DETAILS", user);
            store.commit("SET_USER_AUTHS", {isLoggedIn: true, token: token});
            store.commit("SET_COMPONENT_LAYOUT", { layout: "AdminLayout" });
            localStorage.setItem("user", JSON.stringify(user));
            if(user.roles === 1){
              router.push("/admin")
             }
           }

          next();
        })
        .catch((err) => {
          console.log("error hai routes--", err);
          if ("message" in err) {
            alert(err.message);
          }
          if (err.response.statusText == "Unauthorized") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return next({
              name: "Company LoginPage",
            });
          }
        });
    }
  } else if (to.meta.portal == "Driver") {
    //console.log("Logout")
    // store.dispatch("logOut").then(() => {
    //   next("/login");
    // });

    store.commit("SET_USER_AUTHS", { token: null, isLoggedIn: false });
    store.commit("SET_COMPONENT_LAYOUT", { layout: "DriverLayout" });
    next();
  } else if (to.meta.portal == "Admin"){
    store.commit("SET_USER_AUTHS", { token: null, isLoggedIn: false });
    store.commit("SET_COMPONENT_LAYOUT", { layout: "AdminLayout" });
    return next({
      name: "AdminLoginPage",
    });
  } else {
    store.commit("SET_USER_AUTHS", { token: null, isLoggedIn: false });
    return next({
      name: "Company LoginPage",
    });
  }
}
