<template>
<!-- Admin Header Content -->
<nav class="navbar default-layout col-lg-12 col-12 px-4 py-4 fixed-top d-flex align-items-top flex-row shadow-sm" style="background: #F4F5F7;">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <div class="hamburger me-5">
            <i class="fa-solid fa-bars"></i>
        </div>
        <div class="logo">
            <img src="/images/AskT_Logo 1.png" alt="">
        </div>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center">
        <ul class="">
            <li class="d-none d-lg-block ms-0">
                <h4 class="mb-0">
                    <span class="text-black fw-bold">Admin</span>
                </h4>
            </li>
        </ul>
        <div class="profile-img d-flex ms-auto">
            <div class="dropdown">
                <img class=" dropdown-toggle" type="button" data-toggle="dropdown" data-bs-toggle="dropdown"  aria-expanded="false" src="/images/faces/face8.jpg" alt="Profile">

                <div class="dropdown-menu">
                    <div class="dropdown-header text-center">
                      <img class="img-md rounded-circle" src="/images/faces/face8.jpg" alt="Profile image">
                        <p class="mb-1 mt-3 font-weight-semibold"> Admin </p>
                        <p class="fw-light text-muted mb-0"> admin@gmail.com </p>
                    </div>
                    <ul class="p-menu flex-column align-items-center px-4">
                        <!-- <li class="d-flex align-items-center my-2 cursor">
                            <i class="fa-regular fa-user"></i>
                            <span class="dropdown-item px-3">Profile</span>
                        </li> -->
                        <li @click="logout()" class="d-flex align-items-center my-2 cursor">
                            <i class="fa-solid fa-right-from-bracket"></i>
                            <span class="dropdown-item px-3">Logout</span>
                            <loader v-if="loader" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>
  </template>
  
  <script>
import loader from '../loader.vue';

  export default {
    name: 'HeaderPage',
    components: {
        loader
    },

    data(){
        return{
            loader: false,
        }
    },

    computed:{
      User(){
        return this.$store.state.auth.user;
      }
    },

    methods: {
      logout(){
        this.loader = true;
        this.$store.dispatch('logout',{
          userToken: localStorage.getItem('token'),
          name: 'admin',
        })
        .then((res)=>{
            console.log("res", res);
            this.loader = false;
        })
        .catch((err)=>{
            console.log("err", err);
            this.loader = false;
        })
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .logo img {
    width: 50px;
  }
  
  @media screen and (max-width: 576px) {
    .logo img {
      width: 50px;
    }
  }
  </style>
  