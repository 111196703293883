export const apis = {
    //Dashboard
    GET_DASHBOARD: 'admin/dashboard',
    
    //COMPANY
    GET_COMPANIES: 'admin/companies',
    GET_COMPANY_DETAILS_BY_ID: 'admin/find-company/',
    DELETE_COMPANY_BY_ID: 'admin/delete-company/',

    //DRIVER
    GET_DRIVERS: 'admin/drivers',
    GET_DRIVER_DETAILS_BY_ID: 'admin/find-driver/',
    DELETE_DRIVER_BY_ID: 'admin/delete-driver/',
};