import { createStore } from 'vuex';
// import App from "../../src/app.vue";
//auth and toast
import auth from './auth.js';
import VerifyErrorsAndToasts from './verifyErrorsAndToasts.js';
// import FacebookLogin from './facebookLogin.js';

//admin
import dashboard from './module/admin/dashboard.js';
import adminCompanies from './module/admin/adminCompanies.js';
import adminDrivers from './module/admin/adminDrivers.js';

//driver
import driverProfile from './module/driver/driverProfile.js';
import reviews from './module/driver/reviews.js';
import place from './module/driver/place.js';

//company
import companyProfile from './module/company/companyProfile.js';
import companyView from './module/company/companyView.js';

export default createStore({
  modules: {
    // auth and toast
    auth,
    VerifyErrorsAndToasts,
    // FacebookLogin,

    // admin
    dashboard,
    adminCompanies,
    adminDrivers,

    // driver
    driverProfile,
    reviews,
    place,

    // company
    companyProfile,
    companyView,
  },
});