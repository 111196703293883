<!-- DriverLayout.vue -->
<template>
  <div>
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/driverLayout/Header.vue";
import Footer from "../components/driverLayout/Footer.vue";

export default {
  name: "DriverLayout",
  components: { Header, Footer },
};
</script>
