import { createRouter, createWebHistory } from "vue-router";
// import axios from "axios";
import store from "../store";

import auth from '../middleware/auth'
import middlewarePipeline from './middlewarePipeline'

import DriverLayout from "../layout/DriverLayout.vue";
import CompanyLayout from "../layout/CompanyLayout.vue";
import AdminLayout from "../layout/AdminLayout.vue";

const routes = [
  // **************** Driver Route **************
  {
    path: "/",
    name: "HomePage",
    component: DriverLayout,
    meta: {
      middleware: [
          auth
      ],
      portal: 'Driver'
    },
    // beforeEnter: ((to, from, next) => {
    //   console.log('from--',from);
    //   console.log('to--',to);
    //   var token = localStorage.getItem("token");
    //   // const user = JSON.parse(localStorage.getItem("user"));
    //   // console.log("beforeEach---token--", store);
    //   // console.log("beforeEach---next--", next);
    
    //   var isAuthenticated = Boolean(token);
    //   // var isAuthenticatedUser = Boolean(user);
    
    //   if (isAuthenticated) {
    //       if (store.state.auth.user == null || store.state.auth.user == undefined) {
    //         // var formData2 = new FormData();
    //         // formData2.append("token", token);
    //         axios
    //           .get(axios.defaults.baseURL + "auth/current-user",{
    //             headers: {
    //               Authorization: "Bearer " + token,
    //             },
    //            })
    //           .then((response) => {
    //             console.log("login details--from routes--", response);
    
    //              if(response.data[0].roles == "3" || response.data[0].roles === 3){
    //               let user = {
    //                 address: response.data[0].address,
    //                 contact: response.data[0].contact,
    //                 created_at: response.data[0].created_at,
    //                 driver: response.data[0].driver,
    //                 email: response.data[0].email,
    //                 id: response.data[0].id,
    //                 image: response.data[0].imagelink,
    //                 position: response.data[0].position,
    //                 roles: response.data[0].roles,
    //                 user_id: response.data[0].user_id,
    //                 vehicle_no: response.data[0].vehicle_no,
    //                 token: token,
    //               };
    //                console.log("user", user)
    //                store.commit("SET_LOGIN_DETAILS", user);
    //                localStorage.setItem("user", JSON.stringify(user));
    //              }  
               
    //               next();
    //           })
    //           .catch((err) => {
    //             console.log("error hai routes--", err);
    //             if ( 'message'in err) {
    //               alert(err.message);
    //             }
    //             if (err.response.statusText == "Unauthorized") {
    //               localStorage.removeItem("token");
    //               localStorage.removeItem("user");
                  
    //               router.push("/login-driver");
    //             }
    //           });
    //       }else{
    //         next();
    //       }
       
    //   } else {
    //     console.log('else-3-next',!to.meta.authRequired, !isAuthenticated);
    //     next();

    //   }
    // }),
    children: [
      {
        path: "",
        name: "DriverCompaniesPage",
        component: () => import("../views/driver/company/Companies.vue"),
        meta: { authRequired: false, title: 'Companies | Asktrucker' },
      },
      {
        path: "/company-details=:id",
        name: "CompanyDetailsPage",
        component: () => import("../views/driver/company/Company_details.vue"),
        meta: { authRequired: false, title: 'Company Details | Asktrucker' },
      },
      {
        path: "/add-company-review=:id",
        name: "AddCompanyReviewPage",
        component: () => import("../views/driver/company/Add_company_review.vue"),
        meta: { authRequired: false, title: 'Add Company Review | Asktrucker' },
      },
      {
        path: "/add-registered-company-review",
        name: "AddRegisteredCompanyReviewPage",
        component: () => import("../views/driver/company/Add_registered_company_review.vue"),
        meta: { authRequired: false, title: 'Add Company Review | Asktrucker' },
      },
      {
        path: "/add-new-company-review",
        name: "AddNewCompanyReviewPage",
        component: () => import("../views/driver/company/Add_new_company_review.vue"),
        meta: { authRequired: false, title: 'Add New Company Review | Asktrucker' },
      },
      {
        path: "/reviews",
        name: "ReviewsPage",
        component: () => import("../views/driver/Reviews.vue"),
        meta: { authRequired: false, title: 'Reviews | Asktrucker' },
      },
      {
        path: "/places",
        name: "PlacesPage",
        component: () => import("../views/driver/place/Places.vue"),
        meta: { authRequired: false, title: 'Places | Asktrucker' },
      },
      {
        path: "/place-details-:id",
        name: "PlaceDetailsPage",
        component: () => import("../views/driver/place/Place_details.vue"),
        meta: { authRequired: false, title: 'Places Details | Asktrucker' },
      },
      {
        path: "/add-place-review-:id",
        name: "AddPlaceReviewPage",
        component: () => import("../views/driver/place/Add_place_review.vue"),
        meta: { authRequired: false, title: 'Add Place Review | Asktrucker' },
      },
      {
        path: "/add-place",
        name: "AddPlacePage",
        component: () => import("../views/driver/place/Add_place.vue"),
        meta: { authRequired: false, title: 'Add Place | Asktrucker' },
      },
      {
        path: "/my-place",
        name: "MyPlacesPage",
        component: () => import("../views/driver/place/My_place.vue"),
        meta: { authRequired: false, title: 'My Place | Asktrucker' },
      },
      {
        path: "/profile-driver",
        name: "DriverProfilePage",
        component: () => import("../views/driver/profile/Profile.vue"),
        meta: { authRequired: false, title: 'Profile | Asktrucker' },
      },
      {
        path: "/edit-profile-driver",
        name: "DriverEditProfilePage",
        component: () => import("../views/driver/profile/Edit_profile.vue"),
        meta: { authRequired: false, title: 'Edit Profile | Asktrucker' },
      },
      {
        path: "/about-driver",
        name: "AboutPageDriver",
        component: () => import("../views/driver/About.vue"),
        meta: { authRequired: false, title: 'About Page | Asktrucker' },
      },
      {
        path: "/contact-driver",
        name: "ContactPageDriver",
        component: () => import("../views/driver/Contact_page.vue"),
        meta: { authRequired: false, title: 'Contact Page | Asktrucker' },
      },
    ]
  },


  // **************** Company Route **************
  {
    path: "/",
    name: "CompanyHomePage",
    component: CompanyLayout,
    meta: {
      middleware: [
          auth
      ],
      portal: 'Company'
    },
    // beforeEnter: ((to, from, next) => {
    //   console.log('from--',from);
    //   console.log('to--',to);
    //   var token = localStorage.getItem("token");
    //   // const user = JSON.parse(localStorage.getItem("user"));
    //   // console.log("beforeEach---token--", store);
    //   // console.log("beforeEach---next--", next);
    
    //   var isAuthenticated = Boolean(token);
    //   // var isAuthenticatedUser = Boolean(user);
    
    //   if (to.meta.authRequired) {
    //     if (isAuthenticated) {
    //       if (store.state.auth.user == null || store.state.auth.user == undefined) {
    //         // var formData2 = new FormData();
    //         // formData2.append("token", token);
    //         axios
    //           .get(axios.defaults.baseURL + "auth/current-user",{
    //             headers: {
    //               Authorization: "Bearer " + token,
    //             },
    //            })
    //           .then((response) => {
    //             console.log("login details--from routes--", response);
    
    //             if(response.data[0].roles == "2" || response.data[0].roles === 2){
    //               let user = {
    //                 address: response.data[0].address,
    //                 created_at: response.data[0].created_at,
    //                 company: response.data[0].company,
    //                 email: response.data[0].email,
    //                 id: response.data[0].id,
    //                 image: response.data[0].imagelink,
    //                 representative: response.data[0].representative,
    //                 roles: response.data[0].roles,
    //                 user_id: response.data[0].user_id,
    //                 service: response.data[0].service,
    //                 token: token,
    //               };
    //                 store.commit("SET_LOGIN_DETAILS", user);
    //                 localStorage.setItem("user", JSON.stringify(user));
    //               } 
               
    //               next();
    //           })
    //           .catch((err) => {
    //             console.log("error hai routes--", err);
    //             if ( 'message'in err) {
    //               alert(err.message);
    //             }
    //             if (err.response.statusText == "Unauthorized") {
    //               localStorage.removeItem("token");
    //               localStorage.removeItem("user");
                  
    //               router.push("/login-company");
    //             }
    //           });
    //       }else{
    //         next();
    //       }
    //     } else {
    //       // console.log('else-1-next',!to.meta.authRequired,!isAuthenticated);
    //       next({
    //         name: "CompanyLoginPage",
    //       });
    //     }
    //   } else if (!to.meta.authRequired && !isAuthenticated) {
    //     console.log('else-2-next',!to.meta.authRequired, !isAuthenticated);
    //     next({
    //       name: "CompanyLoginPage",
    //     });
    //   } else {
    //     console.log('else-3-next',!to.meta.authRequired, !isAuthenticated);
    //     next({
    //       name: "CompanyLoginPage",
    //     });

    //   }
    // }),
    children: [
      {
        path: "company",
        name: "CompanyReviewPage",
        component: () => import("../views/company/Company_review.vue"),
        meta: {authRequired: true, title: 'Company Review | Asktrucker' },
      },
      {
        path: "/subscription",
        name: "SubscriptionPage",
        component: () => import("../views/company/Subscription.vue"),
        meta: {authRequired: true, title: 'Subscription | Asktrucker' },
      },
      {
        path: "/all-drivers",
        name: "AllDriverPage",
        component: () => import("../views/company/driver/Drivers.vue"),
        meta: {authRequired: true, title: 'Drivers | Asktrucker' },
      },
      {
        path: "/past-feedback-driver=:id",
        name: "PastFeedbackDriverPage",
        component: () => import("../views/company/driver/Past_feedback_driver.vue"),
        meta: {authRequired: true, title: 'Feedbacks | Asktrucker' },
      },
      {
        path: "/add-review-:id",
        name: "AddReviewPage",
        component: () => import("../views/company/Add_review.vue"),
        meta: {authRequired: true, title: 'Add Review | Asktrucker' },
      },
      {
        path: "/add-option-review",
        name: "AddOptionReviewPage",
        component: () => import("../views/company/Add_option_review.vue"),
        meta: {authRequired: true, title: 'Add Review | Asktrucker' },
      },
      {
        path: "/profile",
        name: "CompanyProfilePage",
        component: () => import("../views/company/profile/Profile.vue"),
        meta: {authRequired: true, title: 'Profile | Asktrucker' },
      },
      {
        path: "/edit-profile",
        name: "CompanyEditProfilePage",
        component: () => import("../views/company/profile/Edit_profile.vue"),
        meta: {authRequired: true, title: 'Edit Profile | Asktrucker' },
      },
      {
        path: "/transaction-history",
        name: "TransactionHistoryPage",
        component: () => import("../views/company/Transaction_history.vue"),
        meta: {authRequired: true, title: 'Transactions History | Asktrucker' },
      },
    ]
  },

    // **************** Admin Route **************
    {
      path: "/",
      name: "DashboardPage",
      component: AdminLayout,
      meta: {
        middleware: [
            auth
        ],
        portal: 'Admin'
      },
      children: [
        {
          path: "admin",
          name: "AdminCompanyReviewPage",
          component: () => import("../views/admin/Dashboard.vue"),
          meta: {authRequired: true, title: 'Company Review | Asktrucker' },
        },
        {
          path: "/companies",
          name: "CompaniesPage",
          component: () => import("../views/admin/Companies.vue"),
          meta: {authRequired: true, title: 'Companies | Asktrucker' },
        },
        {
          path: "/company-details-:id",
          name: "AdminCompanyDetailsPage",
          component: () => import("../views/admin/Company_details.vue"),
          meta: {authRequired: true, title: 'Company Details | Asktrucker' },
        },
        {
          path: "/drivers",
          name: "DriversPage",
          component: () => import("../views/admin/Drivers.vue"),
          meta: {authRequired: true, title: 'Drivers | Asktrucker' },
        },
        {
          path: "/driver-details=:id",
          name: "DriverDetailsPage",
          component: () => import("../views/admin/Driver_details.vue"),
          meta: {authRequired: true, title: 'Driver Details | Asktrucker' },
        },
        {
          path: "/subscriptions",
          name: "SubscriptionsPage",
          component: () => import("../views/admin/Subscriptions.vue"),
          meta: {authRequired: true, title: 'Subscriptions | Asktrucker' },
        },
      ]
    },


    //login register driver
    //login
    {
      path: "/login-driver",
      name: "DriverLoginPage",
      component: () => import("../views/driver/Login.vue"),
      meta: { title: 'Login Page | Asktrucker' },
    },
    //register
    {
      path: "/register-driver",
      name: "DriverRegisterPage",
      component: () => import("../views/driver/Register.vue"),
      meta: { title: 'Register Page | Asktrucker' },
    },

    //login and register company
    //login
    {
    path: "/login-company",
    name: "CompanyLoginPage",
    component: () => import("../views/company/Login.vue"),
    meta: { title: 'Login Page | Asktrucker' },
    },
    //register
    {
      path: "/register-company",
      name: "CompanyRegisterPage",
      component: () => import("../views/company/Register.vue"),
      meta: { title: 'Register Page | Asktrucker' },
    },

    //login and register admin
    //login
    {
    path: "/login-admin",
    name: "AdminLoginPage",
    component: () => import("../views/admin/Login.vue"),
    meta: { title: 'Login Page | Asktrucker' },
    },

    // *********404 Page **********
    {
      path: "/:catchAll(.*)",
      name: "Not Found",
      component: () =>
        import(/* webpackChunkName: "about" */ "../components/notFound.vue"),
      meta: { title: '404 Page Not Found' },
    },

    // ********** success for subscription **********
    {
      path: "/success",
      name: "success",
      component: () =>
        import(/* webpackChunkName: "about" */ "../components/partials/success.vue"),
      meta: { title: 'Success' },
    },
    // ********** error for subscription **********
    {
      path: "/error",
      name: "error",
      component: () =>
        import(/* webpackChunkName: "about" */ "../components/partials/error.vue"),
      meta: { title: 'Error' },
    },

    // ********** Reset password **********
    {
      path: "/reset-password?token=:id",
      name: "resetPassword",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/driver/Reset_password.vue"),
      meta: { title: 'Reset Password' },
    },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


// router.beforeEach((to, from, next) => {
//   console.log('from--',from);
//   console.log('to--',to);
//   var token = localStorage.getItem("token");
//   // const user = JSON.parse(localStorage.getItem("user"));
//   // console.log("beforeEach---token--", store);
//   // console.log("beforeEach---next--", next);
//   // console.log("beforeEach---to--", to);
//   // console.log("beforeEach---from--", from);

//   var isAuthenticated = Boolean(token);
//   // var isAuthenticatedUser = Boolean(user);

//   if (to.meta.authRequired) {
//     if (isAuthenticated) {
//       if (store.state.auth.user == null || store.state.auth.user == undefined) {
//         // var formData2 = new FormData();
//         // formData2.append("token", token);
//         axios
//           .get(axios.defaults.baseURL + "auth/current-user",{
//             headers: {
//               Authorization: "Bearer " + token,
//             },
//            })
//           .then((response) => {
//             console.log("login details--from routes--", response);

//              if(response.data.response[0].roles == "3" || response.data.response[0].roles === 3){
//               let user = {
//                 address: response.data.response[0].address,
//                 contact: response.data.response[0].contact,
//                 created_at: response.data.response[0].created_at,
//                 driver: response.data.response[0].driver,
//                 email: response.data.response[0].email,
//                 id: response.data.response[0].id,
//                 image: response.data.response[0].image,
//                 position: response.data.response[0].position,
//                 roles: response.data.response[0].roles,
//                 user_id: response.data.response[0].user_id,
//                 vehicle_no: response.data.response[0].vehicle_no,
//                 token: token,
//               };
//                store.commit("SET_LOGIN_DETAILS", user);
//                localStorage.setItem("user", JSON.stringify(user));
//              }  
//               else if(response.data.response[0].roles == "2" || response.data.response[0].roles === 2){
//               let user = {
//                 address: response.data.response[0].address,
//                 created_at: response.data.response[0].created_at,
//                 company: response.data.response[0].company,
//                 email: response.data.response[0].email,
//                 id: response.data.response[0].id,
//                 image: response.data.response[0].image,
//                 representative: response.data.response[0].representative,
//                 roles: response.data.response[0].roles,
//                 user_id: response.data.response[0].user_id,
//                 service: response.data.response[0].service,
//                 token: token,
//               };
//                store.commit("SET_LOGIN_DETAILS", user);
//                localStorage.setItem("user", JSON.stringify(user));
//              }
//               next();
//           })
//           .catch((err) => {
//             console.log("error hai routes--", err);
//             if ( 'message'in err) {
//               alert(err.message);
//             }
//             if (err.response.statusText == "Unauthorized") {
//               localStorage.removeItem("token");
//               localStorage.removeItem("user");
              
//               router.push("/login-driver");
//             }
//           });
//       }else{
//         next();
//       }
//     } else {
//       // console.log('else-1-next',!to.meta.authRequired,!isAuthenticated);
//       next({
//         name: "DriverLoginPage",
//       });
//     }
//   } else if (!to.meta.authRequired && !isAuthenticated) {
//     console.log('else-2-next',!to.meta.authRequired, !isAuthenticated);
//     next();
//   } else {
//     console.log('else-3-next',!to.meta.authRequired, !isAuthenticated);
//     next();
//   }
// });

// router.beforeEach((to, from, next) => {
//   // Update the title for each route
//   document.title = to.meta.title || 'AskTruck';
//   next();
// });

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'AskTruck';
  window.scrollTo(0, 0);
  if (!to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware
  console.log('router * middleware', middleware);
  const context = {
      to,
      from,
      next,
      store
  }

   return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })
  // if (re instanceof Promise) {
  //   re.then(()=> middlewarePipeline(context, middleware))
  // }
// })

// function middlewarePipeline(context, middleware, idx=0) {
//   if (idx < middleware.length) {
//     return dispatch(middleware[idx], context, () => {
//         middlewarePipeline(context, middleware, idx + 1)
//     })
//   } else {
//     return context.next()
//   }
// }

// /**
//  * Dispatch a synchronous hook function and return its response.
//  * If the hook returns a promise, resolve it and return its value.
//  */
// function dispatch(hook, context, next) {
//   const res = hook(context)
//   // if hook returns early, call next automatically
//   if (res && !isPromise(res)) {
//     return next()
//   }
//   // expose next as a property of the context so that handlers can call it
//   context.next = next
//   return isPromise(res) ? res.then(next) : next()
// }
});
export default router;
