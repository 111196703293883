<template>
    <div class="sticky-top">
        <header class="header-section">
            <nav class="navbar navbar-expand-lg navbar-light">
                <div class="container">
                    <a class="navbar-brand logoo" href="#"><img src="images/AskT_Logo 1.png"></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fa-solid fa-bars" style="font-size: 26px"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav m-auto mb-2 mb-lg-0">
                            <li class="nav-item" :class="{ 'activee': $route.path === '/company'  || $route.name === 'AddReviewPage' }">
                                <router-link to="/company">
                                    <a class="nav-link px-md-3">Company Reviews</a>
                                </router-link>
                            </li>
                            <li class="nav-item" :class="{ 'activee': $route.path === '/subscription' }">
                                <router-link to="/subscription">
                                   <a class="nav-link px-md-3">Subscriptions</a>
                                </router-link>
                            </li>
                            <li class="nav-item" :class="{ 'activee': $route.path === '/all-drivers' || $route.path === '/add-option-review' || $route.name === 'PastFeedbackDriverPage' }">
                                <router-link to="/all-drivers">
                                   <a class="nav-link px-md-3">Drivers</a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link px-md-3" href="#">About</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link px-md-3" href="#">Contact</a>
                            </li>
                        </ul>
    <!-- 
                        <a href="login.html">Driver Login</a>
                        <div class="me-3"></div>
                        <a href="../company/login.html" style="font-weight: 600;">Company Login</a> -->
                        <div class="d-flex align-items-center">
                          <div class="img">
                            <div class="dropdown">
                                <img v-if="User.image == ''" src="/images/faces/face1.jpg" alt="" data-bs-toggle="dropdown" aria-expanded="false">
                                <img v-if="User.image" :src="User.image" alt="" data-bs-toggle="dropdown" aria-expanded="false">
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                  <li class="m-2">
                                    <router-link to="/profile" class="dropdown-item"><i class="fa-regular fa-user text-success"></i> Profile</router-link>
                                  </li>
                                  <li class="m-2">
                                    <router-link to="/transaction-history" class="dropdown-item"><i class="fa-solid fa-bolt-lightning text-warning"></i> Transaction History</router-link>
                                  </li>
                                  <li class="m-2">
                                    <a class="dropdown-item"  @click="logout()" href="#"><i class="fa-solid fa-arrow-right-from-bracket text-danger"></i> 
                                        <span class="me-3"> Logout</span>
                                        <!-- <loader v-if="loader" /> -->
                                    </a>
                                  </li>
                                </ul>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </div>
  </template>
  
  <script>
  // import loader from '../loader.vue';

  export default {
    name: 'HeaderPage',
    components: {
        // loader
    },

    data(){
        return{
            loader: false,
        }
    },

    computed:{
      User(){
        return this.$store.state.auth.user;
      }
    },

    methods: {
      logout(){
        this.loader = true;
        this.$store.dispatch('logout',{
          userToken: localStorage.getItem('token'),
          name: 'company',
        })
        .then((res)=>{
            console.log("res", res);
            this.loader = false;
        })
        .catch((err)=>{
            console.log("err", err);
            this.loader = false;
        })
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .navbar{
    background-color: rgba(246, 246, 246, .3);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
  }
  </style>
  