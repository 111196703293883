// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from "./apis/auth";
import router from "../router/routes";

const state = {
  // Additional state if needed
  user: null,
  token: null,
  isLoggedIn: false,
  componentLayout: 'DriverLayout',
};

const mutations = {
  SET_LOGIN_DETAILS(state, data) {
    console.log("user set", data);
    state.user = data;
  },
  SET_USER_AUTHS(state, data) {
    console.log("SET_USER_AUTHS set", data);
    state.isLoggedIn = data.isLoggedIn;
    state.token = data.token;
  },
  SET_COMPONENT_LAYOUT(state, data) {
    console.log("SET_COMPONENT_LAYOUT set", data);
    state.componentLayout = data.layout;
  },

  // LOGOUT_USER(state, data) {
  //   state.user = data;
  //   state.token = data;
  // },
};

const actions = {
  // Additional actions if needed
  login({dispatch, commit }, payload) {
    console.log("payload--", payload);

    var formData = new FormData();
    formData.append("email", payload.email);
    formData.append("password", payload.password);

    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.LOGIN, formData)
        .then((res) => {
          console.log("login res--", res);
          resolve(res)
          // var formData2 = new FormData();
          // formData2.append("token", res.data.accessToken);
          axios
            .get(axios.defaults.baseURL + apis.GET_CURRENT_LOGGED_IN_USER, {
              headers: {
                Authorization: "Bearer " + res.data.accessToken,
              },
             }
            )
            .then((response) => {
              console.log("login details-- auth--", response);
              localStorage.setItem("token", res.data.accessToken);
              localStorage.setItem("user", JSON.stringify(response.data[0]));
              
              if(response.data[0].roles == "3" || response.data[0].roles === 3){
                let user = {
                  address: response.data[0].address,
                  contact: response.data[0].contact,
                  created_at: response.data[0].created_at,
                  driver: response.data[0].driver,
                  email: response.data[0].email,
                  id: response.data[0].id,
                  image: response.data[0].imagelink,
                  position: response.data[0].position,
                  roles: response.data[0].roles,
                  user_id: response.data[0].user_id,
                  vehicle_no: response.data[0].vehicle_no,
                  token: res.data.accessToken,
                };
                commit("SET_LOGIN_DETAILS", user);
                commit("SET_USER_AUTHS", {isLoggedIn: true, token: res.data.accessToken});
                commit("SET_COMPONENT_LAYOUT", { layout: "DriverLayout" });
              }
              else if(response.data[0].roles == "2" || response.data[0].roles === 2){
                let user = {
                  address: response.data[0].address,
                  created_at: response.data[0].created_at,
                  company: response.data[0].company,
                  email: response.data[0].email,
                  id: response.data[0].id,
                  image: response.data[0].imagelink,
                  representative: response.data[0].representative,
                  roles: response.data[0].roles,
                  user_id: response.data[0].user_id,
                  service: response.data[0].service,
                  token: res.data.accessToken,
                };
                commit("SET_LOGIN_DETAILS", user);
                commit("SET_USER_AUTHS", {isLoggedIn: true, token: res.data.accessToken});
                commit("SET_COMPONENT_LAYOUT", { layout: "CompanyLayout" });
              }
              else if(response.data[0].roles == "1" || response.data[0].roles === 1){
                let user = {
                  name: response.data[0].name,
                  email: response.data[0].email,
                  id: response.data[0].id,
                  roles: response.data[0].roles,
                  token: res.data.accessToken,
                };
                commit("SET_LOGIN_DETAILS", user);
                commit("SET_USER_AUTHS", {isLoggedIn: true, token: res.data.accessToken});
                commit("SET_COMPONENT_LAYOUT", { layout: "AdminLayout" });
               }

                if (response.data[0].roles == 1) {
                  router.push("/admin").then(() => {
                    router.go();
                  });
                } else if (response.data[0].roles == 2) {
                  router.push("/company").then(() => {
                    router.go();
                  });
                } else {
                  router.push("/")
                }
                // router.push("/").go()
                dispatch("checkErrorAndSendToast", [
                  res,
                  "success",
                ]);
            })
            .catch((err) => {
              console.log("error ff hai--", err);
  
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              // router.go();
            });
        })
        .catch((err) => {
          console.log("login err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          reject(err)
        });
    })
  },

  loginWithGoogle({ commit }, payload) {
        commit("UPDATE_LOADING_STATE", true);
        console.log('login loginWithGoogle', payload);
        // return
        var formData = new FormData()
        formData.append('email', payload.email)
        formData.append('name', payload.name)
        formData.append('roles', payload.roles)
        axios
            .post(axios.defaults.baseURL + apis.LOGIN_WITH_GOOGLE, formData)
            .then((res) => {
                console.log('login loginWithGoogle', res)
                // localStorage.setItem('token', res.data.accessToken)
                // localStorage.setItem('roles', res.data.roles)
                // localStorage.setItem('user', JSON.stringify(res.data))
                // commit('SET_LOGIN_DETAILS', res.data)
                commit("UPDATE_LOADING_STATE", false);
                
                // if (res.data.roles == 1) {
                //     this.$router.push('/admin').this.$router.go()
                // } else {
                //     this.$router.go()
                // }

                axios
                .get(axios.defaults.baseURL + apis.GET_CURRENT_LOGGED_IN_USER, {
                  headers: {
                    Authorization: "Bearer " + res.data.accessToken,
                  },
                 }
                )
                .then((response) => {
                  console.log("login details-- auth--", response);
                  localStorage.setItem("token", res.data.accessToken);
                  localStorage.setItem("user", JSON.stringify(response.data[0]));
                  
                  if(response.data[0].roles == "3" || response.data[0].roles === 3){
                    let user = {
                      address: response.data[0].address,
                      contact: response.data[0].contact,
                      created_at: response.data[0].created_at,
                      driver: response.data[0].driver,
                      email: response.data[0].email,
                      id: response.data[0].id,
                      image: response.data[0].imagelink,
                      position: response.data[0].position,
                      roles: response.data[0].roles,
                      user_id: response.data[0].user_id,
                      vehicle_no: response.data[0].vehicle_no,
                      token: res.data.accessToken,
                    };
                    commit("SET_LOGIN_DETAILS", user);
                    commit("SET_USER_AUTHS", {isLoggedIn: true, token: res.data.accessToken});
                    commit("SET_COMPONENT_LAYOUT", { layout: "DriverLayout" });
                  }
                  else if(response.data[0].roles == "2" || response.data[0].roles === 2){
                    let user = {
                      address: response.data[0].address,
                      created_at: response.data[0].created_at,
                      company: response.data[0].company,
                      email: response.data[0].email,
                      id: response.data[0].id,
                      image: response.data[0].imagelink,
                      representative: response.data[0].representative,
                      roles: response.data[0].roles,
                      user_id: response.data[0].user_id,
                      service: response.data[0].service,
                      token: res.data.accessToken,
                    };
                    commit("SET_LOGIN_DETAILS", user);
                    commit("SET_USER_AUTHS", {isLoggedIn: true, token: res.data.accessToken});
                    commit("SET_COMPONENT_LAYOUT", { layout: "CompanyLayout" });
                  }
                  else if(response.data[0].roles == "1" || response.data[0].roles === 1){
                    let user = {
                      name: response.data[0].name,
                      email: response.data[0].email,
                      id: response.data[0].id,
                      roles: response.data[0].roles,
                      token: res.data.accessToken,
                    };
                    commit("SET_LOGIN_DETAILS", user);
                    commit("SET_USER_AUTHS", {isLoggedIn: true, token: res.data.accessToken});
                    commit("SET_COMPONENT_LAYOUT", { layout: "AdminLayout" });
                   }
    
                    if (response.data[0].roles == 1) {
                      router.push("/admin").then(() => {
                        router.go();
                      });
                    } else if (response.data[0].roles == 2) {
                      router.push("/company").then(() => {
                        router.go();
                      });
                    } else {
                      router.push("/")
                    }
                    // router.push("/").go()
                })
                .catch((err) => {
                  console.log("error ff hai--", err);
      
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  // router.go();
                });
            })
            .catch((err) => {
                console.log(err, 'login err')
                commit("UPDATE_LOADING_STATE", false);
            })
  },



  driverRegister({dispatch, commit}, payload) {
    console.log("payload--", payload);
    // return

    var formData = new FormData();
    formData.append("drivername", payload.drivername);
    formData.append("email", payload.email);
    formData.append("vehicleno", payload.vehicleno);
    formData.append("address", payload.address);
    formData.append("contact", payload.contact);
    formData.append("position", payload.position);
    formData.append("image", payload.image);
    formData.append("dob", payload.dob);
    formData.append("roles", "3");
    formData.append("password", payload.password);
    formData.append("confirm_password", payload.confirm_password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.DRIVER_REGISTER, formData)
        .then((res) => {
          console.log("driver_register res", res);
          commit("UPDATE_LOADING_STATE", false);
          // localStorage.setItem("token", res.data.data.token);
          router.push("/login-driver").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("driver_register err", err);
          reject(err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    })
  },

  companyRegister({dispatch, commit}, payload) {
    console.log("payload--", payload);
    // return
    var formData = new FormData();
    formData.append("image", payload.image);
    formData.append("company", payload.company);
    formData.append("representative", payload.representative);
    formData.append("email", payload.email);
    formData.append("address", payload.address);
    formData.append("service", payload.service);
    formData.append("contact", payload.contact);
    formData.append("roles", "2");
    formData.append("password", payload.password);
    formData.append("confirm_password", payload.confirm_password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.COMPANY_REGISTER, formData)
        .then((res) => {
          console.log("companyRegister res", res);
          commit("UPDATE_LOADING_STATE", false);
          // localStorage.setItem("token", res.data.data.token);
          router.push("/login-company").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("companyRegister err", err);
          reject(err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    })
  },

  logout({dispatch, commit }, payload) {

    return new Promise((resolve, reject) => {
      axios
        .get(axios.defaults.baseURL + apis.LOGOUT, {
          headers: {
            Authorization: "Bearer " + payload.userToken,
          },
         })
        .then((res) => {
          resolve(res);

          console.log("logout res", res);
          commit("UPDATE_LOADING_STATE", false);

          localStorage.removeItem("token");
          localStorage.removeItem("user");

          if(payload.name == 'driver'){
            router.push("/").then(() => {
              router.go();
            });
          }
          else if(payload.name == 'company'){
            router.push("/").then(() => {
              router.go();
            });
          }
          else if(payload.name == 'admin'){
            router.push("/login-admin").then(() => {
              router.go();
            });
          }
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          reject(err);
          console.log("logout err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    })
},


  forgotPassword({dispatch }, payload) {
    var formData = new FormData();
    formData.append("email", payload.email);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.FORGOT_PASSWORD, formData)
        .then((res) => {
          console.log("forgotPassword res", res);

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("forgotPassword err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          reject(err);
        });
    })
  },

  resetPassword({dispatch }, payload) {
    console.log(payload, "pay")
    var formData = new FormData();
    formData.append("token", payload.id);
    formData.append("password", payload.password);
    formData.append("c_password", payload.confirm_password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.RESET_PASSWORD, formData)
        .then((res) => {
          console.log("resetPassword res", res);
          router.push("/").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("resetPassword err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          reject(err);
        });
    })
  },
};

const getters = {
  // Additional getters if needed
  auth(state){
    return state.isLoggedIn
  },
  Layout(state){
    return state.componentLayout
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
