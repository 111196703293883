// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/driver';
// import router from "@/router/routes";
import router from "../../../router/routes";

const state = {
    // Additional state if needed
    driverDetails: '',
}

const mutations = {
    SET_PROFILE_DETAILS_DATA(state, data) {
        state.driverDetails = data
    },
}

const actions = {
    // Additional actions if needed

    getDriverProfile({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_PROFILE_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getDriverProfile res', res)
                      commit('SET_PROFILE_DETAILS_DATA', res.data)
                  })
                  .catch((err) => {
                      console.log('getDriverProfile err', err)
                  })
          // })
    },

    editDriverProfile({dispatch, commit}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("drivername", payload.drivername);
        formData.append("vehicleno", payload.vehicleno);
        formData.append("dob", payload.dob);
        formData.append("address", payload.address);
        formData.append("contact", payload.contact);
        formData.append("position", payload.position);
        formData.append("description", payload.description);
        formData.append("image", payload.image);
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.EDIT_PROFILE_BY_ID + payload.id, formData,{
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("editDriverProfile res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
              ]);
              router.push("/profile-driver")
            })
            .catch((err) => {
              console.log("editDriverProfile err", err);
              reject(err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
      },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };