<template>
    <div>
            <!-- footer section start -->
            <div class="footer-area footer-bg clearfix">
                <div class="footer-details-area pb-30 pt-30">
                    <div class="container">
                        <div class="row justify-content-sm-center justify-content-md-start">
                            <div class="col-12">
                                <div class="d-md-flex align-items-center fd-area">
                                    <img src="/images/AskT_Logo 1.png" class="foot-img" alt="footer image">
                                    <p class="mb-0">Mauris malesuada arcu eu posuere eleifen. Ut egestas tortor at leo egestas tincidunt. Nam
                                        tincidunt metus tristique tristique viverra. Mauris erat ante, egestas vitae dapibus
                                        sed.Mauris malesuada arcu eu posuere eleifen. Ut egestas tortor at leo egestas tincidunt. Nam tincidunt metus tristique tristique viverra. Mauris erat ante, egestas vitae dapibus sed.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6 mt-4">
                                <div class="fd-second-menu mt-0">
                                    <ul class="d-flex flex-wrap">
                                        <li class="mx-2">
                                            <routerLink to="/">
                                                Companies
                                            </routerLink>
                                        </li>
                                        <li class="mx-2">
                                            <routerLink to="/places">
                                                Places
                                            </routerLink>
                                        </li>
                                        <li class="mx-2">
                                            <a href="#">About Us </a>
                                        </li>
                                        <li class="mx-2">
                                            <a href="#">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 fd-area mt-4">
                                  <div class="fd-social-link text-md-end">
                                    <ul>
                                        <li>
                                            <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="fa-brands fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="fa-brands fa-youtube"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
            <!-- footer credit -->
            <footer class="footer-area-copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="ft-copy">
                                CopyRight© 2024 <a href="#">AskTrucker </a>. All Rights Reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <!-- footer credit end -->
            <!-- footer section end -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'FooterPage',
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .foot-img{
    width: 160px;
  }
  @media screen and (max-width: 576px) {
    .fd-area p{
        font-size: 13px;
    }
    .foot-img{
        width: 133px;
    }
  }
  </style>
  