// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    dashboardData: '',
}

const mutations = {
    SET_ADMIN_DASHBOARD_DATA(state, data) {
      state.dashboardData = data
  },
}

const actions = {
    // Additional actions if needed
      getAdminDashboardData({ commit }) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_DASHBOARD, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getAdminDashboardData---', res)
                        commit('SET_ADMIN_DASHBOARD_DATA', res.data)
                    })
                    .catch((err) => {
                        console.log('getAdminDashboardData err', err)
                    })
            // })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };