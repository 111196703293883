<!-- AdminLayout.vue -->
<template>
  <div class="container-scroller">
    <Header />
    <div class="container-fluid page-body-wrapper">
      <Sidebar />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from "../components/AdminLayout/Header.vue";
import Sidebar from "../components/AdminLayout/Sidebar.vue";

export default {
  name: "CompanyLayout",
  components: { Header, Sidebar },
  mounted(){
    console.log('reach layout');

        const sidebar = document.querySelector('.sidebar')
        const sidebarBtn = document.querySelector('.hamburger')

        sidebarBtn.addEventListener('click', () => {
        sidebar.classList.toggle('left');
       })
  }
};
</script>
