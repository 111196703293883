export const apis = {
    //Login
    LOGIN: 'auth/login',
    LOGIN_WITH_GOOGLE: 'auth/google/login',
    DRIVER_REGISTER: 'auth/driver/register',
    COMPANY_REGISTER: 'auth/company/register',
    GET_CURRENT_LOGGED_IN_USER: 'auth/current-user',
    LOGOUT: 'auth/logout',
    FORGOT_PASSWORD: 'auth/forget-password',
    RESET_PASSWORD: 'auth/reset-password',

};