// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    driversData: [],
    driverDetails: '',
}

const mutations = {
    SET_DRIVERS_DATA(state, data) {
      state.driversData = data
    },
    SET_DRIVER_DETAILS_DATA(state, data) {
        state.driverDetails = data
    },
}

const actions = {
    // Additional actions if needed
    getAdminDrivers({ commit }) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_DRIVERS, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getDrivers res', res)
                      commit('SET_DRIVERS_DATA', res.data)
                  })
                  .catch((err) => {
                      console.log('getDrivers err', err)
                  })
          // })
    },

    getAdminDriverDetails({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_DRIVER_DETAILS_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getDriverDetails res', res)
                      commit('SET_DRIVER_DETAILS_DATA', res.data[0])
                  })
                  .catch((err) => {
                      console.log('getDriverDetails err', err)
                  })
          // })
    },

    deleteDriver({dispatch, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.DELETE_DRIVER_BY_ID + id, {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  })
                .then((res) => {
                    console.log('deleteDriver res', res)
                    resolve(res)
                    // dispatch("checkErrorAndSendToast", [
                    //   res,
                    //   "success",
                    // ]);
                    dispatch("getAdminDrivers")
                    commit("UPDATE_LOADING_STATE", false);
                })
                .catch((err) => {
                    console.log('deleteDriver err', err)
                    reject(err)
                    // dispatch("checkErrorAndSendToast", [
                    //   err.response,
                    //   "error",
                    // ]);
                })
        })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };