// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/driver';
import router from "../../../router/routes";

const state = {
    // Additional state if needed
    allPlaces: [],
    myPlaces: [],
    allPlacesById: '',
}

const mutations = {
  SET_All_PLACES_DATA(state, data) {
        state.allPlaces = data
  },
  SET_MY_PLACES_DATA(state, data) {
        state.myPlaces = data
  },

  SET_PLACE_BY_ID_DATA(state, data) {
        state.allPlacesById = data
  },

}

const actions = {
    // Additional actions if needed

    AddPlace({dispatch, commit}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("added_by", payload.added_by);
        formData.append("place", payload.place);
        formData.append("image", payload.image);
        formData.append("service", payload.service);
        formData.append("address", payload.address);
        formData.append("description", payload.description);
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.ADD_PLACE, formData,{
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("add place res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
              ]);
              router.push("/my-place")
            })
            .catch((err) => {
              console.log("add place err", err);
              reject(err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
      },

    getAllPlaces({ commit }) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_PLACE, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getAllPlaces res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_All_PLACES_DATA', res.data)
                      }
                      else{
                        commit('SET_All_PLACES_DATA', res.data.message)
                      }
                  })
                  .catch((err) => {
                      console.log('getAllPlaces err', err)
                  })
          // })
    },

    getPlaceById({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_PLACE_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getPlaceById res', res)
                      commit('SET_PLACE_BY_ID_DATA', res.data)
                  })
                  .catch((err) => {
                      console.log('getPlaceById err', err)
                  })
          // })
    },

    AddReview_ForPlace({dispatch, commit}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("place_id", payload.place_id);
        formData.append("review_id", payload.review_id);
        formData.append("headline", payload.headline);
        formData.append("ratings", payload.ratings);
        formData.append("description", payload.description);
        formData.append("load_unload_time", payload.load_unload_time);
        formData.append("overnight_parking", payload.overnight_parking);
        formData.append("are_people_nice", payload.are_people_nice);
        formData.append("restroom", payload.restroom);
        formData.append("lumper_fee", payload.lumper_fee);
        for (var i = 0; i < payload.image.length; i++) {
          formData.append('image', payload.image[i])
        }
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.ADD_PLACE_REVIEW, formData,{
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("add review for place res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
              ]);
              router.push("/places")
            })
            .catch((err) => {
              console.log("add review for place err", err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
              reject(err);
            });
        })
      },

      getMyPlaces({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.MY_PLACE + id, {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  })
                .then((res) => {
                    console.log('getMyPlaces res', res)
                    if(res.data.places != undefined){
                      commit('SET_MY_PLACES_DATA', res.data.places)
                    }
                    else{
                      commit('SET_MY_PLACES_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getMyPlaces err', err)
                })
        // })
  },

    DeleteMyPlace({dispatch, commit }, payload) {
          // return
          return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.DELETE_PLACE_BY_ID + payload.id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('DeleteMyPlace res', res)
                      commit("UPDATE_LOADING_STATE", false);
                      resolve(res)
                      dispatch('getMyPlaces', payload.user_id)
                      dispatch("checkErrorAndSendToast", [
                        res,
                        "success",
                      ]);
                  })
                  .catch((err) => {
                      console.log('DeleteMyPlace err', err)
                      reject(err)
                      dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                      ]);
                  })
          })
    },


}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };