// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/company';
import router from "../../../router/routes";

const state = {
    // Additional state if needed
    receivedReview: [],
    givenReview: [],
    checkReview: [],
}

const mutations = {
    SET_RECEIVED_REVIEW_DETAILS_DATA(state, data) {
        state.receivedReview = data
    },
    SET_GIVEN_REVIEW_DETAILS_DATA(state, data) {
        state.givenReview = data
    },
    SET_CHECK_REVIEW_USER(state, data) {
        state.checkReview = data
    },
}

const actions = {
    // Additional actions if needed

    getReceivedReview({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.RECEIVED_REV_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getReceivedReview res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_RECEIVED_REVIEW_DETAILS_DATA', res.data)
                      }
                      else{
                        commit('SET_RECEIVED_REVIEW_DETAILS_DATA', res.data.message)
                      }
                  })
                  .catch((err) => {
                      console.log('getReceivedReview err', err)
                  })
          // })
    },

    getGivenReview({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GIVEN_REV_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getGivenReview res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_GIVEN_REVIEW_DETAILS_DATA', res.data)
                      }
                      else{
                        commit('SET_GIVEN_REVIEW_DETAILS_DATA', res.data.message)
                      }

                      const givenUserId = res.data.map((el)=>{
                       return parseInt(el.user_id);
                      })
                      commit('SET_CHECK_REVIEW_USER', givenUserId)
                  })
                  .catch((err) => {
                      console.log('getGivenReview err', err)
                  })
          // })
    },

    AddReview_ForDriver({dispatch, commit}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("user_id", payload.user_id);
        formData.append("review_id", payload.review_id);
        formData.append("headline", payload.headline);
        formData.append("ratings", payload.ratings);
        formData.append("description", payload.description);
        formData.append("home_time", '');
        formData.append("equipment", '');
        formData.append("dispatches", '');
        formData.append("status", payload.status);
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.ADD_REVIEW_FOR_DRIVER, formData,{
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("AddReview_ForDriver res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
              ]);
              router.push("/company")
            })
            .catch((err) => {
              console.log("AddReview_ForDriver err", err);
              reject(err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
      },

    Add_Outside_Review_ForDriver({dispatch, commit}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("review_id", payload.review_id);
        formData.append("driver", payload.driver);
        formData.append("dob", payload.dob);
        formData.append("headline", payload.headline);
        formData.append("ratings", payload.ratings);
        formData.append("description", payload.description);
        formData.append("status", payload.status);
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.ADD_OUTSIDE_REVIEW_FOR_DRIVER, formData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("Add_Outside_Review_ForDriver res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
              ]);
              router.push("/all-drivers")
            })
            .catch((err) => {
              console.log("Add_Outside_Review_ForDriver err", err);
              reject(err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
      },

      postSubscription({dispatch, commit}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("user_id", payload.user_id);
        formData.append("amount", payload.amount);
        formData.append("end_date", payload.end_date);
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.SUBSCRIPTION, formData,{
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("postSubscription res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
              ]);
              // router.push("/company")
            })
            .catch((err) => {
              console.log("postSubscription err", err);
              reject(err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
      },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };