// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    companiesData: [],
    companiesDetails: '',
}

const mutations = {
    SET_COMPANIES_DATA(state, data) {
      state.companiesData = data
    },
    SET_COMPANY_DETAILS_DATA(state, data) {
        state.companiesDetails = data
    },
}

const actions = {
    // Additional actions if needed
    getAdminCompanies({ commit }) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_COMPANIES, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getCompanies res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_COMPANIES_DATA', res.data)
                      }
                      else{
                        commit('SET_COMPANIES_DATA', res.data.message)
                      }
                  })
                  .catch((err) => {
                      console.log('getCompanies err', err)
                  })
          // })
    },

    getAdminCompaniesDetails({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_COMPANY_DETAILS_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getCompanyDetails res', res)
                      commit('SET_COMPANY_DETAILS_DATA', res.data[0])
                  })
                  .catch((err) => {
                      console.log('getCompanyDetails err', err)
                  })
          // })
    },

    
    deleteCompany({dispatch, commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.DELETE_COMPANY_BY_ID + id, {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  })
                .then((res) => {
                    console.log('deleteCompany res', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                      res,
                      "success",
                    ]);
                    dispatch("getAdminCompanies")
                    commit("UPDATE_LOADING_STATE", false);
                })
                .catch((err) => {
                    console.log('deleteCompany err', err)
                    reject(err)
                    dispatch("checkErrorAndSendToast", [
                      err.response,
                      "error",
                    ]);
                })
        })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };