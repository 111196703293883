<template>
    <div class="sidebar shadow-sm">
        <!-- <div class="logo-details">
            <div>
              <span class="logo_name pl-3">SOUQ</span>
            </div>
            <div class="hamburger">
              <i class="fa-solid fa-bars"></i>
            </div>
          </div> -->
        <ul class="nav-links" style="margin-top: 80px">
          <li>
            <router-link class="nav-link" to="/admin">
              <i class="fa-solid fa-table-columns menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/companies">
              <i class="fa-solid fa-building menu-icon"></i>
              <span class="menu-title">Companies</span>
            </router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/drivers">
              <i class="fa-solid fa-id-card menu-icon"></i>
              <span class="menu-title">Drivers</span>
            </router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/subscriptions">
              <i class="fa-solid fa-money-bill menu-icon"></i>
              <span class="menu-title">Subscriptions</span>
            </router-link>
          </li>

        </ul>
      </div>
  </template>
  
  <script>


  export default {
    name: 'SidebarPage',
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  