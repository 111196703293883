// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/driver';
import router from "../../../router/routes";

const state = {
    // Additional state if needed
    allReviews: [],
    myReviews: [],
    pastReviews: [],
    checkMyReviews: [],
}

const mutations = {
  SET_All_REVIEW_DATA(state, data) {
        state.allReviews = data
  },

  SET_MY_REVIEW_DATA(state, data) {
        state.myReviews = data
  },
  SET_PAST_REVIEW_DATA(state, data) {
        state.pastReviews = data
  },
  SET__CHECK_MY_REVIEW(state, data) {
        state.checkMyReviews = data
  },

}

const actions = {
    // Additional actions if needed

    getAllReviews({ commit }) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.GET_REVIEW, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getAllReviews res', res)
                      commit('SET_All_REVIEW_DATA', res.data.response)
                  })
                  .catch((err) => {
                      console.log('getAllReviews err', err)
                  })
          // })
    },

    getMyReviews({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.MY_REVIEW_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getMyReviews res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_MY_REVIEW_DATA', res.data)
                      }
                      else{
                        commit('SET_MY_REVIEW_DATA', res.data.error)
                      }
                      
                      const alldriverReview = res.data.map((el)=>{
                        return el.company_id
                      })
                      commit('SET__CHECK_MY_REVIEW', alldriverReview)
                  })
                  .catch((err) => {
                      console.log('getMyReviews err', err)
                  })
          // })
    },

    getPastReviews({ commit }, id) {
          // return
          // return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.PAST_REVIEW_BY_ID + id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('getPastReviews res', res)
                      if(Array.isArray(res.data)){
                        commit('SET_PAST_REVIEW_DATA', res.data)
                      }
                      else{
                        commit('SET_PAST_REVIEW_DATA', res.data.message)
                      }
                  })
                  .catch((err) => {
                      console.log('getPastReviews err', err)
                  })
          // })
    },

    DeleteMyReviews({dispatch, commit }, payload) {
          // return
          return new Promise((resolve, reject) => {
              axios
                  .get(axios.defaults.baseURL + apis.DELETE_REVIEW_BY_ID + payload.id, {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                    })
                  .then((res) => {
                      console.log('DeleteMyReviews res', res)
                      commit("UPDATE_LOADING_STATE", false);
                      resolve(res)
                      dispatch('getMyReviews', payload.user_id)
                      dispatch('getGivenReview', payload.user_id)
                      dispatch("checkErrorAndSendToast", [
                        res,
                        "success",
                      ]);
                  })
                  .catch((err) => {
                      console.log('DeleteMyReviews err', err)
                      reject(err)
                      dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                      ]);
                  })
          })
    },

    AddReview_ForCompany({dispatch, commit}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("user_id", payload.user_id);
        formData.append("review_id", payload.review_id);
        formData.append("headline", payload.headline);
        formData.append("ratings", payload.ratings);
        formData.append("home_time", payload.home_time);
        formData.append("dispatches", payload.dispatches);
        formData.append("equipment", payload.equipment);
        formData.append("employment_type", payload.employment_type);
        formData.append("employement_status", payload.employement_status);
        formData.append("description", payload.description);
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.ADD_COMPANY_REVIEW, formData,{
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("add review for company res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
              ]);
              router.push("/")
            })
            .catch((err) => {
              console.log("add review for company err", err);
              reject(err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
      },

    AddReview_For_Outside_Company({dispatch, commit}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("review_id", payload.review_id);
        formData.append("headline", payload.headline);
        formData.append("ratings", payload.ratings);
        formData.append("home_time", payload.home_time);
        formData.append("dispatches", payload.dispatches);
        formData.append("equipment", payload.equipment);
        formData.append("employement_type", payload.employement_type);
        formData.append("employement_status", payload.employement_status);
        formData.append("description", payload.description);
        formData.append("contact", payload.contact);
        formData.append("mcnumber", payload.mcnumber);
        formData.append("dotnumber", payload.dotnumber);
        formData.append("address", payload.address);
        formData.append("image", payload.image);
        formData.append("service", payload.service);
        formData.append("representative", payload.representative);
        formData.append("company", payload.company);
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.ADD_OUTSIDE_COMPANY_REVIEW, formData,{
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("add review for outside company res", res);
              commit("UPDATE_LOADING_STATE", false);
              resolve(res);
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
              ]);
              router.push("/")
            })
            .catch((err) => {
              console.log("add review for outside company err", err);
              reject(err);
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
        })
      },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };