export const apis = {
    //profile
    GET_PROFILE_BY_ID: 'company/profile/',
    EDIT_PROFILE_BY_ID: 'company/profile/',
    
    //companyView
    RECEIVED_REV_BY_ID: 'company/received-review/',
    GIVEN_REV_BY_ID: 'company/given-review/',
    ADD_REVIEW_FOR_DRIVER: 'review/add-driver-review',
    ADD_OUTSIDE_REVIEW_FOR_DRIVER: 'review/add-outside-driver-review',
    SUBSCRIPTION: 'subscription',


};