<template>
    <div class="sticky-top">
        <header class="header-section">
            <nav class="navbar navbar-expand-lg navbar-light">
                <div class="container">
                    <RouterLink class="navbar-brand logoo" to="/"><img src="/images/AskT_Logo 1.png"></RouterLink>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fa-solid fa-bars" style="font-size: 26px"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav m-auto mb-2 mb-lg-0">
                            <li class="nav-item" :class="{ 'activee': $route.path === '/reviews' }">
                                <a href="https://projects.dotlinkertech.com/frontend/phpBB3/" target="_blank">
                                    <a class="nav-link px-md-3">Forum</a>
                                </a>
                            </li>
                            <li class="nav-item" :class="{ 'activee': $route.path === '/' || $route.path === '/add-registered-company-review' || $route.name === 'AddCompanyReviewPage' || $route.path === '/add-new-company-review' || $route.name === 'CompanyDetailsPage' }">
                                <RouterLink to="/">
                                    <a class="nav-link px-md-3">Companies</a>
                                  </RouterLink>
                            </li>
                            <li class="nav-item" :class="{ 'activee': $route.path === '/places' || $route.path === '/add-place' || $route.name === 'PlaceDetailsPage' || $route.name === 'AddPlaceReviewPage'}">
                                <RouterLink to="/places">
                                    <a class="nav-link px-md-3">Places</a>
                                </RouterLink>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  Tools
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                  <li><a class="dropdown-item" href="https://www.ventusky.com/" target="_blank">Weather 24/7</a></li>
                                  <!-- <li><a class="dropdown-item" href="#">Another action</a></li>
                                  <li><hr class="dropdown-divider"></li>
                                  <li><a class="dropdown-item" href="#">Something else here</a></li> -->
                                </ul>
                              </li>
                            <li class="nav-item" :class="{ 'activee': $route.path === '/about-driver' }">
                                 <RouterLink to="/about-driver">
                                   <a class="nav-link px-md-3" href="#">About</a>
                                </RouterLink>
                            </li>
                            <li class="nav-item" :class="{ 'activee': $route.path === '/contact-driver' }">
                                 <RouterLink to="/contact-driver">
                                   <a class="nav-link px-md-3" href="#">Contact</a>
                                </RouterLink>
                            </li>
                        </ul>
    
                        <router-link v-if="!User" to="/login-driver">
                            Driver Login
                        </router-link>
                        <div class="me-3"></div>
                        <router-link v-if="!User" to="/login-company" style="font-weight: 600;">
                            Company Login
                        </router-link>
                        <div v-if="User" class="d-flex align-items-center ms-2">
                          <div class="img">
                            <div class="dropdown">
                                <img v-if="User.image == ''" src="/images/faces/face1.jpg" alt="" data-bs-toggle="dropdown" aria-expanded="false">
                                <img v-if="User.image" :src="User.image" alt="" data-bs-toggle="dropdown" aria-expanded="false">
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li class="m-2">
                                        <router-link to="/profile-driver" class="dropdown-item"><i class="fa-regular fa-user text-success"></i> Profile</router-link>
                                    </li>
                                    <li class="m-2">
                                        <router-link to="/my-place" class="dropdown-item"><i class="fa-regular fa-map text-info"></i> My Places</router-link>
                                    </li>
                                    <li class="m-2">
                                        <a class="dropdown-item" @click="logout()" href="#"><i class="fa-solid fa-arrow-right-from-bracket text-danger"></i>
                                            <span class="me-3"> Logout</span>
                                            <loader v-if="loader" />
                                        </a>
                                    </li>
                                </ul>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </div>
  </template>
  
  <script>
  import loader from '../loader.vue';
  export default {
    name: 'HeaderPage',
    components: {
        loader
    },

    data(){
        return{
            loader: false,
        }
    },

    computed:{
      User(){
        return this.$store.state.auth.user;
      }
    },

    methods: {
      logout(){
        this.loader = true;
        this.$store.dispatch('logout',{
          userToken: localStorage.getItem('token'),
          name: 'driver',
        })
        .then((res)=>{
            console.log("res", res);
            this.loader = false;
        })
        .catch((err)=>{
            console.log("err", err);
            this.loader = false;
        })
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .navbar{
        background-color: rgba(246, 246, 246, .3);
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
    }
  </style>
  